import { default as accountXekD3F3D4mMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/account.vue?macro=true";
import { default as indexQxBjuQNzyrMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/index.vue?macro=true";
import { default as blockchain_45walletyOmJpfFHdYMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/blockchain-wallet.vue?macro=true";
import { default as dedicated_45addresscAgfnOway0Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/dedicated-address.vue?macro=true";
import { default as optionsVrhz7J9kj0Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options.vue?macro=true";
import { default as indexFX5Ff7l2ppMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/index.vue?macro=true";
import { default as binanceminaZNXkCnMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/binance.vue?macro=true";
import { default as _91network_93og4DEKCCUwMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/[network].vue?macro=true";
import { default as indexz3cJ8DE4JiMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/index.vue?macro=true";
import { default as brlazqxTlGiRNiMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/brla.vue?macro=true";
import { default as coinbase_walletYrUzYRTsLlMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase_wallet.vue?macro=true";
import { default as coinbasedraXB3GpCuMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase.vue?macro=true";
import { default as eurpGv0ukAHmWMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/eur.vue?macro=true";
import { default as forexhtVzUfqtugMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/forex.vue?macro=true";
import { default as gbpVXyhrdLL8aMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/gbp.vue?macro=true";
import { default as international_bankSlZin2Cg3tMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international_bank.vue?macro=true";
import { default as internationalGAiGhMCvxgMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international.vue?macro=true";
import { default as krakene19H8zKRhBMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/kraken.vue?macro=true";
import { default as ngnceeb3k278mMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/ngn.vue?macro=true";
import { default as usVLcCmP1VB7Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/us.vue?macro=true";
import { default as optionshCrX5oCr63Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options.vue?macro=true";
import { default as ramps40xbNm3cIBMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/ramps.vue?macro=true";
import { default as coinbasecA7BdsR7s1Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/coinbase.vue?macro=true";
import { default as verify_45wallet6oqx1d2FY0Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/verify-wallet.vue?macro=true";
import { default as _91uuid_93vvW8OrVwcWMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_93FDAX0pvF0cMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as loginfuQunt1gd6Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/login.vue?macro=true";
import { default as register0Aqz1r1ETbMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/register.vue?macro=true";
import { default as blockedvYgktyVGgjMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/blocked.vue?macro=true";
import { default as classificationNkJhVc658VMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/classification.vue?macro=true";
import { default as indexLsJouxJgpqMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/index.vue?macro=true";
import { default as indexLPC2U3CCv6Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/index.vue?macro=true";
import { default as dashboardBOHPqUPzHMMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/dashboard.vue?macro=true";
import { default as developerFni6HMpjXtMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/developer.vue?macro=true";
import { default as pay_scheduleq3PZlFkBl3Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/[payschedule]/pay_schedule.vue?macro=true";
import { default as indexvPmcCEXe5dMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/index.vue?macro=true";
import { default as _91accountId_939inIL4EZy0Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/dedicated-address/[accountId].vue?macro=true";
import { default as deposit_45usHU57Ihm23aMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit-us.vue?macro=true";
import { default as depositsHgT9V2vQLMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit.vue?macro=true";
import { default as _91accountId_933VFxBfsTJjMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/external-account/[accountId].vue?macro=true";
import { default as riseidni1oTevykxMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/riseid.vue?macro=true";
import { default as fundEefnYCSjxEMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund.vue?macro=true";
import { default as index1CpaCpRoBJMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/index.vue?macro=true";
import { default as _91code_938XHNC8ABxTMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invite/[code].vue?macro=true";
import { default as _91id_93ijazF8aLzzMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/[id].vue?macro=true";
import { default as index9gH6yEJlUuMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/index.vue?macro=true";
import { default as indexRGpcfOtXQXMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/index.vue?macro=true";
import { default as jwtdTm0PW22VGMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/jwt.vue?macro=true";
import { default as indexXx6dSsO5iOMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/index.vue?macro=true";
import { default as teamw49sOKZYWcMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/team.vue?macro=true";
import { default as indexuGBeJhXOpyMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/index.vue?macro=true";
import { default as onboardingDuay6sIKoUMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/onboarding.vue?macro=true";
import { default as hourlypQVmmPoOayMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/hourly.vue?macro=true";
import { default as instantphoSWfkl8vMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/instant.vue?macro=true";
import { default as milestonebkxqpdgdpEMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/milestone.vue?macro=true";
import { default as oncepDPaJq8eUFMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/once.vue?macro=true";
import { default as recurring5F3sz6qiibMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/recurring.vue?macro=true";
import { default as _91userId_93kk88hOJBrPMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId].vue?macro=true";
import { default as csvczZVRbZ89vMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/csv.vue?macro=true";
import { default as indexpzKUuW6kZ9Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/index.vue?macro=true";
import { default as indexplfQol97zCMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/index.vue?macro=true";
import { default as managementhFvfmQZhtYMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/management.vue?macro=true";
import { default as _91id_93kKUsjpC2V4Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/fund/[id].vue?macro=true";
import { default as _91id_93yfkYBwZn4XMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/payment/[id].vue?macro=true";
import { default as _91psId_937PBc1O6xbaMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/[psId].vue?macro=true";
import { default as index74iAHfNTZjMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/index.vue?macro=true";
import { default as transaction_45historyGe872PhmDBMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/transaction-history.vue?macro=true";
import { default as playgroundzJwUFxe9WOMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/playground.vue?macro=true";
import { default as businessGKmOr3dCoWMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/business.vue?macro=true";
import { default as indexrJMBVnLWD6Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/index.vue?macro=true";
import { default as referralGhjepE2gjkMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/referral.vue?macro=true";
import { default as riseid_45erc20_45withdrawKiEtKUhDqqMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid-erc20-withdraw.vue?macro=true";
import { default as riseidAQAQmoJ3sNMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid.vue?macro=true";
import { default as securityvsclkVihL8Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/security.vue?macro=true";
import { default as sign_45documentu6BXBepVq4Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-document.vue?macro=true";
import { default as _2fa2zDizt2Z7UMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/2fa.vue?macro=true";
import { default as callback4pmdzogePmMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/callback.vue?macro=true";
import { default as code7fr4J33jowMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/code.vue?macro=true";
import { default as indexWDZqR3z4ugMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/index.vue?macro=true";
import { default as sign_45outVRuuIsMXgZMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-out.vue?macro=true";
import { default as coderDIHj0PNRmMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/code.vue?macro=true";
import { default as indexjOEnesYM4SMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/index.vue?macro=true";
import { default as waitingyEl2JEEed5Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/waiting.vue?macro=true";
import { default as indexZLbkX5zLTJMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/index.vue?macro=true";
import { default as optionsU6L2KOitofMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/options.vue?macro=true";
import { default as indexveNcFpZSOyMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/[id]/index.vue?macro=true";
import { default as importU1tDemztOFMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/import.vue?macro=true";
import { default as indexKsKbp4E1k9Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/index.vue?macro=true";
import { default as batch_warmed_lightwuD2uL3O06Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed_light.vue?macro=true";
import { default as batch_warmedlknT1dh84GMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed.vue?macro=true";
import { default as directplmfQqhSJwMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/direct.vue?macro=true";
import { default as sumsubsqEwstnthFMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/sumsub.vue?macro=true";
import { default as warmed_lightUblJVDKkfAMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed_light.vue?macro=true";
import { default as warmedRsciBQUxG2Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed.vue?macro=true";
import { default as inviteOL63kLQRwIMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite.vue?macro=true";
import { default as index98seMkyTVDMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/tasks/index.vue?macro=true";
import { default as _91accountId_93REMbuJZJvBMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/blockchain/[accountId].vue?macro=true";
import { default as _91accountId_93pVRfbVPvOTMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/wire/[accountId].vue?macro=true";
import { default as withdrawWqn7POROgHMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountXekD3F3D4mMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding",
    path: "/accounts/add-funding",
    meta: indexQxBjuQNzyrMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding-options",
    path: "/accounts/add-funding/options",
    meta: optionsVrhz7J9kj0Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options.vue").then(m => m.default || m),
    children: [
  {
    name: "accounts-add-funding-options-blockchain-wallet",
    path: "blockchain-wallet",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/blockchain-wallet.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding-options-dedicated-address",
    path: "dedicated-address",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/dedicated-address.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "accounts-add-withdraw",
    path: "/accounts/add-withdraw",
    meta: indexFX5Ff7l2ppMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options",
    path: "/accounts/add-withdraw/options",
    meta: optionshCrX5oCr63Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options.vue").then(m => m.default || m),
    children: [
  {
    name: "accounts-add-withdraw-options-binance",
    path: "binance",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/binance.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-blockchain-wallet-network",
    path: "blockchain-wallet/:network()",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/[network].vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-blockchain-wallet",
    path: "blockchain-wallet",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-brla",
    path: "brla",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/brla.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-coinbase_wallet",
    path: "coinbase_wallet",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase_wallet.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-eur",
    path: "eur",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/eur.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-forex",
    path: "forex",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/forex.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-gbp",
    path: "gbp",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/gbp.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-international_bank",
    path: "international_bank",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international_bank.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-international",
    path: "international",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-kraken",
    path: "kraken",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/kraken.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-ngn",
    path: "ngn",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/ngn.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-us",
    path: "us",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/us.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "accounts-add-withdraw-ramps",
    path: "/accounts/add-withdraw/ramps",
    meta: ramps40xbNm3cIBMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/ramps.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-callback-coinbase",
    path: "/accounts/add/callback/coinbase",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-callback-verify-wallet",
    path: "/accounts/add/callback/verify-wallet",
    meta: verify_45wallet6oqx1d2FY0Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/verify-wallet.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/b2b/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "blocked",
    path: "/blocked",
    meta: blockedvYgktyVGgjMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/blocked.vue").then(m => m.default || m)
  },
  {
    name: "clients-id-classification",
    path: "/clients/:id()/classification",
    meta: classificationNkJhVc658VMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/classification.vue").then(m => m.default || m)
  },
  {
    name: "clients-id",
    path: "/clients/:id()",
    meta: indexLsJouxJgpqMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "clients",
    path: "/clients",
    meta: indexLPC2U3CCv6Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardBOHPqUPzHMMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "developer",
    path: "/developer",
    meta: developerFni6HMpjXtMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/developer.vue").then(m => m.default || m)
  },
  {
    name: "documents-payschedule-pay_schedule",
    path: "/documents/:payschedule()/pay_schedule",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/[payschedule]/pay_schedule.vue").then(m => m.default || m)
  },
  {
    name: "documents",
    path: "/documents",
    meta: indexvPmcCEXe5dMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "fund",
    path: "/fund",
    meta: fundEefnYCSjxEMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-dedicated-address-accountId",
    path: "dedicated-address/:accountId()",
    meta: _91accountId_939inIL4EZy0Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/dedicated-address/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "fund-deposit-us",
    path: "deposit-us",
    meta: deposit_45usHU57Ihm23aMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit-us.vue").then(m => m.default || m)
  },
  {
    name: "fund-deposit",
    path: "deposit",
    meta: depositsHgT9V2vQLMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit.vue").then(m => m.default || m)
  },
  {
    name: "fund-external-account-accountId",
    path: "external-account/:accountId()",
    meta: _91accountId_933VFxBfsTJjMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/external-account/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "fund-riseid",
    path: "riseid",
    meta: riseidni1oTevykxMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/riseid.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: index1CpaCpRoBJMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-code",
    path: "/invite/:code()",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invite/[code].vue").then(m => m.default || m)
  },
  {
    name: "invoices-add-id",
    path: "/invoices/add/:id()",
    meta: _91id_93ijazF8aLzzMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/[id].vue").then(m => m.default || m)
  },
  {
    name: "invoices-add",
    path: "/invoices/add",
    meta: index9gH6yEJlUuMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: indexRGpcfOtXQXMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "jwt",
    path: "/jwt",
    meta: jwtdTm0PW22VGMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/jwt.vue").then(m => m.default || m)
  },
  {
    name: "management-company-create",
    path: "/management/company/create",
    meta: indexXx6dSsO5iOMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/index.vue").then(m => m.default || m)
  },
  {
    name: "management-company-create-team",
    path: "/management/company/create/team",
    meta: teamw49sOKZYWcMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/team.vue").then(m => m.default || m)
  },
  {
    name: "management-company",
    path: "/management/company",
    meta: indexuGBeJhXOpyMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingDuay6sIKoUMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId",
    path: "/payments/:userId()",
    meta: _91userId_93kk88hOJBrPMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "payments-userId-hourly",
    path: "hourly",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/hourly.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-instant",
    path: "instant",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/instant.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-milestone",
    path: "milestone",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/milestone.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-once",
    path: "once",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/once.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-recurring",
    path: "recurring",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/recurring.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "payments-batch-csv",
    path: "/payments/batch/csv",
    meta: csvczZVRbZ89vMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/csv.vue").then(m => m.default || m)
  },
  {
    name: "payments-batch",
    path: "/payments/batch",
    meta: indexpzKUuW6kZ9Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/index.vue").then(m => m.default || m)
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexplfQol97zCMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-management",
    path: "/payments/management",
    meta: managementhFvfmQZhtYMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/management.vue").then(m => m.default || m)
  },
  {
    name: "payments-receipts-fund-id",
    path: "/payments/receipts/fund/:id()",
    meta: _91id_93kKUsjpC2V4Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/fund/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-receipts-payment-id",
    path: "/payments/receipts/payment/:id()",
    meta: _91id_93yfkYBwZn4XMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-timesheets-psId",
    path: "/payments/timesheets/:psId()",
    meta: _91psId_937PBc1O6xbaMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/[psId].vue").then(m => m.default || m)
  },
  {
    name: "payments-timesheets",
    path: "/payments/timesheets",
    meta: index74iAHfNTZjMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-transaction-history",
    path: "/payments/transaction-history",
    meta: transaction_45historyGe872PhmDBMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/transaction-history.vue").then(m => m.default || m)
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: "profile-business",
    path: "/profile/business",
    meta: businessGKmOr3dCoWMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/business.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexrJMBVnLWD6Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "referral",
    path: "/referral",
    meta: referralGhjepE2gjkMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: "riseid-erc20-withdraw",
    path: "/riseid-erc20-withdraw",
    meta: riseid_45erc20_45withdrawKiEtKUhDqqMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid-erc20-withdraw.vue").then(m => m.default || m)
  },
  {
    name: "riseid",
    path: "/riseid",
    meta: riseidAQAQmoJ3sNMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid.vue").then(m => m.default || m)
  },
  {
    name: "security",
    path: "/security",
    meta: securityvsclkVihL8Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/security.vue").then(m => m.default || m)
  },
  {
    name: "sign-document",
    path: "/sign-document",
    meta: sign_45documentu6BXBepVq4Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-document.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/2fa.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-callback",
    path: "/sign-in/callback",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/callback.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-out",
    path: "/sign-out",
    meta: sign_45outVRuuIsMXgZMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waitingyEl2JEEed5Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/waiting.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "/subscription",
    meta: indexZLbkX5zLTJMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "subscription-options",
    path: "/subscription/options",
    meta: optionsU6L2KOitofMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/options.vue").then(m => m.default || m)
  },
  {
    name: "talent-id",
    path: "/talent/:id()",
    meta: indexveNcFpZSOyMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "talent-import",
    path: "/talent/import",
    meta: importU1tDemztOFMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/import.vue").then(m => m.default || m)
  },
  {
    name: "talent",
    path: "/talent",
    meta: indexKsKbp4E1k9Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/index.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite",
    path: "/talent/invite",
    meta: inviteOL63kLQRwIMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite.vue").then(m => m.default || m),
    children: [
  {
    name: "talent-invite-batch_warmed_light",
    path: "batch_warmed_light",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed_light.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-batch_warmed",
    path: "batch_warmed",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-direct",
    path: "direct",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/direct.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-sumsub",
    path: "sumsub",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/sumsub.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-warmed_light",
    path: "warmed_light",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed_light.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-warmed",
    path: "warmed",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tasks",
    path: "/tasks",
    meta: index98seMkyTVDMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "withdraw",
    path: "/withdraw",
    meta: withdrawWqn7POROgHMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "withdraw-blockchain-accountId",
    path: "blockchain/:accountId()",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/blockchain/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "withdraw-wire-accountId",
    path: "wire/:accountId()",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/wire/[accountId].vue").then(m => m.default || m)
  }
]
  }
]